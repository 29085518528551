import BadgeLink from 'components/BadgeLink'
import BlockContent from 'components/BlockContent'
import Container from 'components/Container'
import Image from 'components/Image'
import PostCard from 'components/PostCard'
import ProductCard from 'components/ProductCard'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Post = ({
  id,
  title,
  category,
  excerpt,
  image,
  body,
  morePosts,
  recentPosts,
  relatedProducts,
}) => {
  const relatedPosts = [...morePosts, ...recentPosts].slice(0, 3)
  const products = relatedProducts.map(product => {
    return {
      title: product.name,
      url: product.slug.current,
      image: product.variants[0].images[0].image,
      buttonText: 'Shop Now',
    }
  })

  return (
    <>
      <Container
        max={'68rem'}
        css={{
          width: '100%',
          padding: '4.5rem 1rem',
          [theme.medium]: {
            paddingTop: '6rem',
            paddingBottom: '6rem',
          },
          [theme.large]: {
            paddingTop: '7.5rem',
            paddingBottom: '7.5rem',
          },
        }}
      >
        <div
          css={{
            maxWidth: '48rem',
            margin: 'auto',
          }}
        >
          {category &&
            category.map(cat => <BadgeLink to={cat.slug.current}>{cat.title}</BadgeLink>)}
          <h1
            css={{
              ...theme.h2,
              color: theme.colors.spinachDark,
              margin: '2rem 0',
            }}
          >
            {title}
          </h1>
        </div>
        <div
          css={{
            paddingBottom: '56.25%',
            position: 'relative',
            [theme.mediumDown]: {
              marginLeft: '-1rem',
              marginRight: '-1rem',
            },
          }}
        >
          <Image
            {...image}
            width={1088}
            alt={title}
            css={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <p
          css={{
            ...theme.lead,
            color: theme.colors.spinachDark,
            margin: '2rem auto',
            maxWidth: '48rem',
          }}
        >
          {excerpt}
        </p>
        <div
          css={{
            h1: { ...theme.h1 },
            h2: { ...theme.h2 },
            h3: { ...theme.h3 },
            h4: { ...theme.h4 },
            h5: { ...theme.h5 },
            h6: { ...theme.h6 },
            'h2, h3, h4, h5, h6': { margin: '3rem 0 0' },
            'ul, ol': { margin: '2rem 0', lineHeight: '1.6' },
            color: theme.colors.spinachDark,
            '& > div > *': {
              maxWidth: '48rem',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            a: {
              background: `linear-gradient(to bottom, ${theme.colors.broccoli} 0%, ${theme.colors.broccoli} 100%)`,
              backgroundPosition: '0 100%',
              backgroundRepeat: 'repeat-x',
              backgroundSize: '4px 4px',
              color: theme.colors.spinachDark,
              textDecoration: 'none',
              transition: 'background-size .2s',
              ':hover': {
                color: theme.colors.spinachDark,
                backgroundSize: '4px 100%',
              },
            },
          }}
        >
          <BlockContent blocks={body} />
        </div>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '0.5rem',
            marginTop: '4rem',
          }}
        >
          {products.map((item, i) => (
            <ProductCard key={i} item={item} width={361} height={435} />
          ))}
        </div>
      </Container>

      <Container
        wrapperStyles={{
          backgroundColor: theme.colors.creamlessDark,
        }}
        css={{
          paddingTop: '2rem',
          paddingBottom: '2rem',
          [theme.medium]: {
            paddingTop: '2rem',
            paddingBottom: '2rem',
          },
          [theme.large]: {
            paddingTop: '3rem',
            paddingBottom: '3rem',
          },
        }}
      >
        {relatedPosts && relatedPosts.length > 0 && (
          <div
            css={{
              [theme.medium]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridGap: '2rem 5%',
              },
            }}
          >
            {relatedPosts.map(post => (
              <PostCard key={post.id} {...post} />
            ))}
          </div>
        )}
      </Container>
    </>
  )
}

Post.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  body: PropTypes.array.isRequired,
  morePosts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.shape({
          current: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
      excerpt: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ),
  relatedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      variants: PropTypes.array,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  recentPosts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.shape({
          current: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
      excerpt: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ),
}

export default Post
