import Analytics from 'components/Analytics'
import Post from 'components/Post'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { productsActions } from 'store/products/slice'

const PostTemplate = ({
  data: { post, recentPosts, allSanityProduct },
  pageContext: { pageUrl },
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(productsActions.setProducts(allSanityProduct.nodes))
  }, [])

  return (
    <>
      <SEO defaultMeta={{ metaTitle: post.title }} pageMeta={post.meta} pagePath={pageUrl} />
      <Analytics {...post.analytics} />
      <Post {...post} recentPosts={recentPosts.nodes} />
    </>
  )
}

export default PostTemplate

PostTemplate.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      meta: PropTypes.object,
      analytics: PropTypes.object,
      title: PropTypes.string,
      category: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.shape({
          current: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      body: PropTypes.arrayOf(
        PropTypes.shape({
          _key: PropTypes.string.isRequired,
          _type: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      relatedProducts: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          variants: PropTypes.array,
          slug: PropTypes.shape({
            current: PropTypes.string.isRequired,
          }).isRequired,
        })
      ),
      morePosts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          category: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.shape({
              current: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
          slug: PropTypes.shape({
            current: PropTypes.string.isRequired,
          }).isRequired,
          excerpt: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired,
        })
      ),
    }).isRequired,
    recentPosts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          category: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.shape({
              current: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
          slug: PropTypes.shape({
            current: PropTypes.string.isRequired,
          }).isRequired,
          excerpt: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired,
        })
      ),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
}

export const query = graphql`
  query PostQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      meta {
        ...MetaCard
      }
      analytics {
        ...AnalyticsCard
      }
      title
      category {
        title
        slug {
          current
        }
      }
      image {
        ...ImageWithPreview
      }
      excerpt
      body: _rawBody(resolveReferences: { maxDepth: 5 })
      relatedProducts {
        name
        slug {
          current
        }
        variants {
          images {
            image {
              ...ImageWithPreview
            }
          }
        }
      }
      morePosts {
        id
        title
        excerpt
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
      }
    }
    recentPosts: allSanityPost(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        excerpt
        category {
          title
          slug {
            current
          }
        }
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
      }
    }
    allSanityProduct {
      nodes {
        ...Product
      }
    }
  }
`
